import request from '@/utils/request.js'
/*api Phụ huynh */
export function CreateParentInfo(data) {
  return request({
    url: 'GSParent/CreateParentInfo',
    method: 'post',
    data: data
  })
}
export function CreateCourse(data) {
  return request({
    url: 'GSParent/CreateCourse',
    method: 'post',
    data: data
  })
}

// http://localhost:1989/{R:1}