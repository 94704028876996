import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import { authentication } from '../firebase-config'
// import Layout from '../layout'
// import Layout from '../views/admin/layout'
// import { createRouter, createMemoryHistory  } from 'vue-router'
Vue.use(VueRouter)



const routes = [
    {
        path: '/',
        name: 'Đồng hành cùng phụ huynh',
        component: Home
    },
    {
        path: '/gia-su',
        name: 'Đồng hành cùng sinh viên BK',
        component: () => import ("../views/teacher/index.vue")
    },
    {
        path: '/gia-su-dang-ky/:req',
        name: 'Gia sư đăng ký',
        component: () => import ("../views/home/parent-view.vue")
    },
    {
        path: '/invite-student/:req/:id',
        name: 'Gia sư ứng tuyển',
        component: () =>
            import ('../views/teacher/invite-student')
    },
    // {
    //     path: '/admin/account',
    //     name: 'account',
    //     component: () => import ('../views/admin/menu.vue'),
    //     children: [
    //         {
    //           path: '/danh-sach',
    //           name: 'Đơn đặt',
    //           component: () => import('../views/admin/post/index.vue')
    //         }
    //       ]
    //     // meta: { requiresAuth: true }
    // },
    // {
    //     path: '/san-pham',
    //     name: 'Product',
    //     component: () =>
    //         import ('../views/Product.vue'),
    //     // meta: { requiresAuth: true }
    // },
    // {
    //     path: '/thanh-vien',
    //     name: 'Members',
    //     component: () =>
    //         import ('../views/Members.vue'),
    //     // meta: { requiresAuth: true }
    // },
]

    // const router = createRouter({
    //     history: createMemoryHistory(),
    //     routes: routes
    // })

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
 
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
   
        return Promise.reject(err)
    })
}

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = authentication.currentUser;

    if (requiresAuth && !isAuthenticated) {
        next('/admin')
    } else {
        next()
    }
})

export default router