<template>
  <div class="container">
    <div class="container-home">
      <div class="container-post">
        <h4 class="title">Giới thiệu</h4>
        <img class="image-post" src="@/assets/Giasu.jpeg" alt="" />
        <p class="content">
          Theo thống kê, có trên 30% sinh viên Bách khoa đã từng hoặc có nguyện
          vọng làm gia sư các môn như Toán, Lý, Hóa, tiếng Anh, Tin học, ...
          Nhằm giảm thiểu các chi phí về thời gian và tiền bạc cho các bên liên
          quan, Dự án An sinh phát triển một hệ thống CNTT hỗ trợ tìm kiếm sinh
          viên dạy kèm phù hợp với nhu cầu gia sư của Phụ huynh.<br /><br />
          Chúng tôi mong nhận được sự hỗ trợ của các Phụ huynh, thông qua việc
          cung cấp thông tin tìm gia sư theo phiếu gửi yêu cầu dưới đây. Hệ
          thống sẽ tự động tìm kiếm, gửi danh sách các sinh viên phù hợp với yêu
          cầu của Phụ huynh theo thông tin quý vị cung cấp.
        </p>
        <p style="text-align: right">Nhóm iCTSV&EMGLABVN</p>
      </div>
      <div class="container-bill">
        <h3 class="title">Phiếu phụ huynh gửi thông tin</h3>
        <p
          class="des-p"
          style="color: #006161; font-weight: 500; margin-top: 20px"
        >
          Thông tin người học
        </p>
        <div style="display: flex; justify-content: space-between">
          <p class="des-p">Nội dung hỗ trợ</p>
          <!-- <button class="bt-subject">Chọn</button> -->
        </div>
        <div style="display: flex; justify-content: space-between">
          <div style="width: calc(50% - 8px)">
            <span class="size-color">Kiến thức</span>
            <el-checkbox-group v-model="supportSubject">
              <span v-for="item in subjects" :key="item">
                <el-checkbox
                  :label="item"
                  border
                  size="small"
                  style="margin-bottom: 4px; margin-right: 6px; max-width: 100%"
                ></el-checkbox>
              </span>
            </el-checkbox-group>
          </div>
          <div style="width: calc(50% - 8px)">
            <span class="size-color">Kỹ năng</span>
            <el-checkbox-group v-model="supportSkill">
              <span v-for="item in skills" :key="item">
                <el-checkbox
                  :label="item"
                  border
                  size="small"
                  style="margin-bottom: 4px; margin-right: 6px; max-width: 100%"
                ></el-checkbox>
              </span>
            </el-checkbox-group>
          </div>
        </div>

        <!-- <p class="des-p">Cấp học - Lớp học</p> -->
        <div style="display: flex; justify-content: space-between">
          <div style="width: calc(50% - 8px)">
            <span class="size-color">Cấp học</span>
            <div v-for="(item, index) in options" :key="index">
              <el-radio v-model="level" :label="item.value">{{
                item.value
              }}</el-radio>
            </div>
          </div>
          <div style="width: calc(50% - 8px)">
            <span class="size-color">Lớp học</span>
            <div v-for="(item, index) in classes" :key="index">
              <el-radio v-model="valueClass" :label="item.value">{{
                item.value
              }}</el-radio>
            </div>
          </div>
        </div>
        <p class="size-color">Mục tiêu</p>
        <el-checkbox-group v-model="support">
          <div v-for="(item, index) in listPerpose" :key="index">
            <el-checkbox
              :label="item"
              border
              size="small"
              style="margin-bottom: 4px; max-width: 100%"
            ></el-checkbox>
          </div>
        </el-checkbox-group>
        <el-input
          v-if="isShowMorePerpose"
          size="small"
          placeholder="Nhập mục tiêu"
          v-model="morePerpose"
        >
        </el-input>
        <p class="size-color">Thời gian biểu dạy kèm (Gia sư)</p>
        <p
          style="font-size: 12px; color: #e9446a"
          v-if="supportTime.length == 0"
        >
          Thêm các khung giờ dạy mà bạn muốn
        </p>
        <div
          v-for="(time, index) in supportTime"
          :key="index"
          style="display: flex"
        >
          <p
            style="
              font-size: 12px;
              height: 16px;
              margin: 0;
              margin-bottom: 8px;
              margin-left: 8px;
            "
          >
            Lịch học {{ index + 1 }}:
            <span style="color: #0272dd">{{ time }}</span>
          </p>
          <button
            style="background: none; border: none; color: #454545; height: 16px"
            @click="btDeleteTime(time)"
          >
            <i class="el-icon-close"></i>
          </button>
        </div>
        <el-time-select
          v-model ="timeStart"
          :picker-options="{
            start: '07:30',
            step: '00:30',
            end: '21:00',
          }"
          style="width: 35%"
          size="small"
          placeholder="Bắt đầu"
          :editable="false"
        />
        <el-select
          v-model="weekDay"
          placeholder="Thứ"
          size="small"
          style="width: 35%; margin: auto 4px"
        >
          <el-option
            v-for="item in lstWeekDay"
            :key="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-button
          type="success"
          size="small"
          icon="el-icon-plus"
          @click="addTime"
          >Thêm</el-button
        >

        <p class="size-color">Hình thức dạy</p>
        <div>
          <el-radio v-model="formTeach" label="Online">Online</el-radio>
          <el-radio v-model="formTeach" label="Offline">Offline</el-radio>
          <el-radio v-model="formTeach" label="On & Off">Cả 2</el-radio>
        </div>
        <p class="size-color">Hình thức học</p>
        <div>
          <el-radio v-model="quantityStudent" label="1-1">1-1</el-radio>
          <el-radio v-model="quantityStudent" label="Nhóm (tối đa 3 người)"
            >Nhóm (tối đa 3 người)</el-radio
          >
        </div>

        <p class="size-color">Thông tin thêm về người học</p>
        <el-input
          type="textarea"
          placeholder="Giới tính, tính hình, kết quả học tập..."
          v-model="infoMore"
        ></el-input>
        <p
          class="des-p"
          style="color: #006161; font-weight: 500; margin-top: 20px"
        >
          Mong muốn về người dạy kèm (Gia sư)
        </p>

        <div style="margin-top: 8px">
          <el-radio v-model="isSelectSchool" label="Sinh viên Bách khoa HN"
            >Sinh viên Bách khoa HN</el-radio
          >
          <el-radio v-model="isSelectSchool" label="Khác">Khác</el-radio>
        </div>
        <div style="display: flex">
          <p class="des-p">Giới tính:</p>
          <div style="display: flex; margin-top: 10px; margin-left: 10px">
            <el-radio v-model="sexTeacher" label="Nam">Nam</el-radio>
            <el-radio v-model="sexTeacher" label="Nữ">Nữ</el-radio>
            <el-radio v-model="sexTeacher" label="Nam&Nữ">Nam&Nữ</el-radio>
          </div>
        </div>

        <p class="size-color">Yêu cầu khác</p>
        <el-input
          type="textarea"
          placeholder="Nhập yêu cầu thêm về người dạy kèm"
          v-model="needMore"
        ></el-input>
        <p
          class="des-p"
          style="color: #006161; font-weight: 500; margin-top: 20px"
        >
          Thông tin liên hệ
        </p>
        <p class="des-p">Họ và tên</p>
        <el-input
          size="small"
          placeholder="Nhập tên của bạn"
          v-model="nameParent"
        >
        </el-input>
        <p class="des-p">Số điện thoại</p>
        <el-input
          size="small"
          placeholder="Nhập số điện thoại"
          v-model="phoneEmail"
        >
        </el-input>
        <div
          style="display: flex; justify-content: space-between; margin-top: 8px"
        >
          <p class="des-p">Địa chỉ dạy</p>
          <el-button
            type="success"
            size="mini"
            @click="showMap"
            plain
            icon="el-icon-location"
            >Lấy vị trí</el-button
          >
        </div>

        <div>
          <p v-if="addressUser" class="des-p" style="margin: 10px">
            Khu vực:<span style="color: #0272dd; margin-left: 10px">{{
              addressUser
            }}</span>
          </p>
          <el-input
            v-if="addressUser"
            size="small"
            placeholder="Nhập số nhà, đường..."
            v-model="addressParent"
            style="margin-bottom: 8px"
          >
          </el-input>
        </div>
        <div style="height: 100%; margin-top: 8px" v-if="isShowMap">
          <div style="display: flex; justify-content: space-between">
            <p
              style="
                color: red;
                font: italy;
                font-size: 14px;
                margin-bottom: 4px;
              "
            >
              Click vào bản đồ để lấy vị trí của người học
            </p>
            <button
              style="
                background: none;
                border: none;
                font-size: 14px;
                color: #0272dd;
                margin-bottom: -12px;
              "
              @click="btCloseMap"
            >
              Đóng bản đồ
            </button>
          </div>
          <div id="mapid">
            <l-map
              ref="myMap"
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              @update:center="centerUpdate"
              @update:zoom="zoomUpdate"
              @click="clickmap"
            >
              <l-tile-layer :url="url" />
              <l-marker :lat-lng="withPopup">
                <l-icon :icon-anchor="staticAnchor">
                  <img src="@/assets/logomap.png" />
                </l-icon>
              </l-marker>
            </l-map>
          </div>
        </div>

        <div style="margin-top: 16px; text-align: center">
          <el-button
            type="primary"
            size="small"
            @click="btCheckInfo"
            v-if="isShowConfirm"
            >Kiểm tra lại thông tin</el-button
          >
          <el-button
            type="success"
            size="small"
            :loading="isClickBtSend"
            @click="btSendForm"
            v-else
            >Gửi thông tin</el-button
          >
        </div>
      </div>
    </div>

    <div class="container-notify" v-if="isShowNotifySuccess">
      <div class="notify">
        <el-result
          icon="success"
          title="Phụ huynh gửi thông tin phiếu thành công"
          subTitle="Chúng tôi sẽ phản hồi vào email hoặc số điện thoại mà phụ huynh đã cung cấp"
        >
          <template slot="extra">
            <el-button type="success" size="small" @click="btConfirm"
              >Xác nhận</el-button
            >
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import DatePicker2 from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import { informationSupport } from "../../firebase-config";
// import moment from "moment";
import { latLng } from "leaflet";
import { CreateParentInfo } from "@/api/parentApi.js";
export default {
  // components: { DatePicker2 },
  data() {
    return {
      sexTeacher: "Nam",
      isSelectSchool: "Sinh viên Bách khoa HN",
      support: [],
      level: "",
      valueClass: "",
      classes: [],

      options: [
        {
          value: "Tiểu học",
          classes: [
            { value: "Lớp 1" },
            { value: "Lớp 2" },
            { value: "Lớp 3" },
            { value: "Lớp 4" },
            { value: "Lớp 5" },
          ],
        },
        {
          value: "THCS",
          classes: [
            { value: "Lớp 6" },
            { value: "Lớp 7" },
            { value: "Lớp 8" },
            { value: "Lớp 9" },
          ],
        },
        {
          value: "THPT",
          classes: [
            { value: "Lớp 10" },
            { value: "Lớp 11" },
            { value: "Lớp 12" },
          ],
        },
        {
          value: "Đại học",
          classes: [
            { value: "Năm nhất" },
            { value: "Năm 2" },
            { value: "Năm 3" },
            { value: "Năm 4" },
            { value: "Năm 4+" },
          ],
        },
        {
          value: "Khác",
          classes: [{ value: "Khác" }],
        },
      ],
      // subjects: [
      //   { value: "Toán" },
      //   { value: "Vật lý" },
      //   { value: "Hóa học" },
      //   { value: "Tiếng anh" },
      //   { value: "Tư vấn tâm lý" },
      //   { value: "Các môn tiểu học" },
      //   { value: "Khác" },
      // ],
      subjects: [
        "Toán",
        "Vật lý",
        "Hóa học",
        "Tiếng anh",
        "Ngữ văn",
        "Tư vấn tâm lý",
        "Các môn tiểu học",
        "Khác",
      ],
      skills: ["Tin học văn phòng", "Lập trình STEM", "Khác"],
      // skills: [
      //   { value: "Tin học văn phòng" },
      //   { value: "Lập trình STEM" },
      //   { value: "Khác" },
      // ],
      supportSubject: [],
      supportSkill: [],
      listPerpose: [
        "Học cùng, giải tỏa căng thẳng học tập",
        "Hướng dẫn phương pháp học tập",
        "Xây dựng động lực học tập",
        "Cải thiện kết quả",
        "Mục tiêu khác",
      ],
      isShowMorePerpose: false,
      morePerpose: "",
      infoMore: "",
      needMore: "",
      nameParent: "",
      phoneEmail: "",
      addressParent: "",
      isShowNotifySuccess: false,
      isClickBtSend: false,

      supportTime: [],
      formTeach: "Offline",
      quantityStudent: "1-1",

      timeStart: "",
      weekDay: "Thứ 2",
      lstWeekDay: [
        { value: "Thứ 2" },
        { value: "Thứ 3" },
        { value: "Thứ 4" },
        { value: "Thứ 5" },
        { value: "Thứ 6" },
        { value: "Thứ 7" },
        { value: "Chủ Nhật" },
      ],
      coordsUser: null,
      addressUser: "",

      isShowMap: false,
      location: null,
      isShowConfirm: true,

      // MAP
      zoom: 16,
      center: latLng(21.004988169511012, 105.84349504177669),
      mapOptions: {
        zoomSnap: 0.5,
      },
      staticAnchor: [22, 44],
      withPopup: latLng(20.98821751266775, 105.84078311920167),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    };
  },

  watch: {
    level() {
      if (this.level != "") {
        this.classes = this.options.filter(
          (le) => le.value == this.level
        )[0].classes;
        this.valueClass = this.classes[0].value;
      }
    },
    support() {
      var a = this.support.filter((p) => p == "Mục tiêu khác");
      if (a.length > 0) {
        this.isShowMorePerpose = true;
      } else {
        this.isShowMorePerpose = false;
      }
    },
  },

  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    btCloseMap() {
      this.isShowMap = false;
    },
    btCheckInfo() {
      if (this.supportSubject.length == 0) {
        this.$message({
          message: "Vui lòng chọn nội dung hỗ trợ",
          type: "warning",
        });
        return;
      }
      if (this.support.length == 0) {
        this.$message({
          message: "Vui lòng chọn nội dung hỗ trợ",
          type: "warning",
        });
        return;
      }
      if (this.level == "") {
        this.$message({
          message: "Vui lòng chọn cấp học",
          type: "warning",
        });
        return;
      }
      if (this.nameParent == "") {
        this.$message({
          message: "Vui lòng điền họ tên phụ huynh",
          type: "warning",
        });
        return;
      }
      if (this.phoneEmail == "") {
        this.$message({
          message: "Vui lòng điền số điện thoại liên lac",
          type: "warning",
        });
        return;
      }
      if (this.addressParent == "") {
        this.$message({
          message: "Vui lòng điền địa chỉ của bạn",
          type: "warning",
        });
        return;
      }
      if (this.supportTime.length == 0) {
        this.$message({
          message: "Vui lòng thêm thời gian dạy kèm",
          type: "warning",
        });
        return;
      }
      if (this.location == null) {
        this.$message({
          message: "Vui lòng chọn vị trí của bạn trên GoogleMap",
          type: "warning",
        });
        return;
      }
      this.isShowConfirm = false;
      this.isShowMap = false;
      window.scrollTo(0, 600);
    },
    clickmap(item) {
      this.withPopup = latLng(item.latlng.lat, item.latlng.lng);
      this.coordsUser = item.latlng;
      this.getLocation();
    },
    btDeleteTime(time) {
      this.supportTime = this.supportTime.filter((p) => p != time);
    },

    // btGiasu() {
    //   this.$router.push({ path: "/gia-su" });
    // },
    getLocation() {
      var linkLocation =
        "https://maps.vnpost.vn/addr-service/api/Boundary/RevertGeoCoding?x=" +
        this.coordsUser.lng +
        "&y=" +
        this.coordsUser.lat;
      axios
        .get(linkLocation)
        .then((response) => {
          var add = response.data.data;
          this.location = response.data.data;
          this.addressUser =
            add.wardName + ", " + add.districtName + ", " + add.cityName;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showMap() {
      this.isShowMap = true;
    },
    btSendForm() {
      if (this.supportSubject.length == 0) {
        this.$message({
          message: "Vui lòng chọn nội dung hỗ trợ",
          type: "warning",
        });
        return;
      }
      if (this.support.length == 0) {
        this.$message({
          message: "Vui lòng chọn nội dung hỗ trợ",
          type: "warning",
        });
        return;
      }
      if (this.level == "") {
        this.$message({
          message: "Vui lòng chọn cấp học",
          type: "warning",
        });
        return;
      }
      if (this.nameParent == "") {
        this.$message({
          message: "Vui lòng điền họ tên phụ huynh",
          type: "warning",
        });
        return;
      }
      if (this.phoneEmail == "") {
        this.$message({
          message: "Vui lòng điền số điện thoại hoặc email liên lac",
          type: "warning",
        });
        return;
      }
      if (this.addressParent == "") {
        this.$message({
          message: "Vui lòng điền địa chỉ của bạn",
          type: "warning",
        });
        return;
      }
      if (this.supportTime.length == 0) {
        this.$message({
          message: "Vui lòng thêm thời gian dạy kèm",
          type: "warning",
        });
        return;
      }
      if (this.location == null) {
        this.$message({
          message: "Vui lòng chọn vị trí của bạn trên GoogleMap",
          type: "warning",
        });
        return;
      }
      this.isClickBtSend = true;
      if (this.isShowMorePerpose) {
        this.support.push(this.morePerpose);
      }
      const req = {
        NameParent: this.nameParent,
        PhoneEmail: this.phoneEmail,
        AddressParent: this.addressParent,
        FormTeach: this.formTeach,
        InfoMore: this.infoMore,
        Level: this.level,
        Latitude: this.coordsUser.lat,
        Longitude: this.coordsUser.lng,
        NeedMore: this.needMore,
        SexTeacher: this.sexTeacher,
        QuantityStudent: this.quantityStudent,
        SelectSchool: this.isSelectSchool,
        ValueClass: this.valueClass,
        City: this.location.cityName,
        District: this.location.districtName,
        Ward: this.location.wardName,
        NameSupports: this.support.join(";"),
        Subjects: this.supportSubject.join(";"),
        TimeSupport: this.supportTime.join(";"),
        SkillSupport: this.supportSkill.join(";"),
      };
      CreateParentInfo({ ParentInfo: req }).then((res) => {
        if (res.RespCode == 0) {
          this.isClickBtSend = false;
          this.isShowNotifySuccess = true;
          this.sexTeacher = "Nam";
          this.isSelectSchool = "Sinh viên Bách khoa";
          this.support = [];
          this.level = "";
          this.valueClass = "";
          this.classes = [];
          this.nameParent = "";
          this.phoneEmail = "";
          this.addressParent = "";
          this.infoMore = "";
          this.needMore = "";
          this.coordsUser = null;
          this.location = null;
          this.supportSubject = [];
          this.supportSkill = [];
          this.supportTime = [];
          this.formTeach = "Offline";
          this.quantityStudent = "1-1";
        } else {
          this.isClickBtSend = false;
          this.$message({
            message: "Vui lòng kiểm tra lại đường truyền mạng",
            type: "error",
          });
        }
      });
    },

    btConfirm() {
      this.isShowNotifySuccess = false;
    },
    addTime() {
      console.log("anhthanh", this.timeStart);
      if (!this.timeStart) {
        this.$message({
          message: "Vui lòng nhập khung giờ dạy kèm",
          type: "warning",
        });
        return;
      }
      let time = this.timeStart + " - " + this.weekDay;
      this.supportTime.push(time);
    },
  },
};
</script>

<style lang="scss" scoped>
.size-color {
  color: #409eff;
  font-size: 14px;
  font-weight: 500;
}
.container {
  position: relative;
  .container-home {
    padding-top: 1px;
    padding-bottom: 1px;
    .container-post {
      margin: 10px;
      padding: 16px;
      background: rgb(255, 255, 255);
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      .title {
        margin: 0;
        margin-bottom: 16px;
        text-align: center;
        color: #006161;
      }
      .image-post {
        width: 100%;
        border-radius: 8px;
      }
      .content {
        margin: 0;
        margin-top: 8px;
      }
    }
    .container-bill {
      margin: 10px;
      padding: 16px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      .input-level {
        margin-right: 4px;
      }
      .input-class {
        margin-left: 4px;
      }
      .title {
        margin: 0 8px 0 0;
        text-align: center;
        color: #006161;
      }
      .des-p {
        margin: 8px 0px;
        font-size: 14px;
      }
      .bt-subject {
        border: none;
        background: none;
        color: #009394;
      }
      .el-checkbox {
        margin-right: 0px;
      }
    }
  }
  .container-notify {
    position: fixed;
    background: rgba(0, 0, 0, 0.54);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    text-align: center;
    transition: all 0.5s ease-in;
    .notify {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgb(245, 255, 223);
      width: 360px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }
  }
}
.background-load-location {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.54);
  z-index: 20;
  .frame-location {
    background: #fff;
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 10px;
    border-radius: 16px;
  }
}
#mapid {
  height: 440px;
}
</style>
<style>
.container-bill .el-input__inner {
  font-size: 14px !important;
}
.container-bill .el-textarea__inner {
  font-size: 14px !important;
  resize: none;
}
</style>
