<template>
  <div id="app">
    <Nav/>
     <router-view />
     <Footer/>
  </div>
</template>

<script>
import Nav from "./views/nav/index.vue"
import Footer from './views/footer/index.vue'
export default {
  name: 'App',
  components:{
    Nav,
    Footer
  }
}
</script>

<style>
@import "./assets/css/style.css";
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

</style>
