<template>
  <div class="container">
    <div class="container-nav">
      <div id="menu" class="icon" @click="isShowMenu = !isShowMenu">
        <i class="fa-solid fa-bars"></i>
      </div>
      <p>{{ title }}</p>
      <img class="image" src="@/assets/logodonghanh.png" alt="" />
    </div>
    <div class="container-menu" :class="{ checked: isShowMenu }">
      <p>Bài viết</p>
      <p @click="btParent">Phiếu phụ huynh</p>
      <p @click="btStudent">Phiếu gia sư</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowMenu: false,
      title: "Đồng hành cùng phụ huynh",
    };
  },
  methods: {
    btParent() {
      // this.title = "Đồng hành cùng phụ huynh";
      this.$router.push({ path: "/" });
    },
    btStudent() {
      // this.title = "Đồng hành cùng sinh viên Bách khoa";
      this.$router.push({ path: "/gia-su" });
    },
  },
  created() {
    this.$watch(
      () => this.$route.name,
      () => {
        this.isShowMenu = false
        this.title = this.$route.name
        //  console.log( this.$route.name);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.container {
  // background: #0094947a;
  // height: 1400px;
  // max-width: 600px;
  // margin: 0 auto;
  position: relative;
  .container-nav {
    background: rgb(255, 255, 255);
    height: 48px;
    box-shadow: 0 2px 5px rgb(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    .icon {
      background: #006161;
      height: 32px;
      width: 32px;
      margin-top: 6px;
      margin-left: 10px;
      color: #fff;
      // border: 1px solid #;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;

      i {
        font-size: 20px;
        line-height: 32px;
      }
    }
    .icon:hover {
      background: #008585;
    }
    p {
      font-family: "Dancing Script", cursive;
      color: #e9446a;
      font-size: 18px;
      line-height: 48px;
      margin: 0;
      font-weight: bold;
    }
    .image {
      height: 32px;

      margin-top: 8px;
      margin-right: 10px;
    }
  }
  .container-menu {
    position: absolute;
    top: 48px;
    left: -200px;
    width: 200px;
    background: #008585;
    color: #fff;
    transition: all 0.5s ease;
    opacity: 0;
    z-index: 10;
    // padding: 16px auto;
    padding-top: 12px;
    padding-bottom: 12px;
    p {
      // padding: 10px;
      // display: block;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
.checked {
  left: 0px !important;
  opacity: 1 !important;
}
</style>
