import axios from 'axios'
import { Message } from 'element-ui'
// import store from '@/store'
// import { getToken, getUserID } from '@/utils/auth'
// import { baseUrl } from './URL'

// create an axios instance
const service = axios.create({
  baseURL: "https://ansinh.emglab.vn/GIASUAPI/", // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

// request interceptor
// service.interceptors.request.use(
//   config => {

//     if (store.getters.token) {
//       config.data.Token = ""
//       config.data.UserName = ""
//     }
//     return config
//   },
//   error => {
//     console.log(error) 
//     return Promise.reject(error)
//   }
// )

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.data == null) {
      response.data = {}
    }
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    // if (res.RespCode !== 0) {
    //   Message({
    //     message: res.RespText || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    //   if (res.RespCode === -1) {
    //     Promise.reject()
    //     MessageBox.confirm('Bạn đã bị đăng xuất, Đăng nhập lại?', 'Thông báo', {
    //       confirmButtonText: 'Đăng nhập',
    //       cancelButtonText: 'Hủy',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   } else {
    //     // sendLogError(res.RespCode, res.RespText)
    //   }
    //   Promise.reject()
      return res
    // }
    // if (res.RespCode === 503) {
    //   Message({
    //     message: res.RespText || 'Error',
    //     type: 'warning',
    //     duration: 5 * 1000
    //   })
    // } else {
    //   return res
    // }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: 'Hệ thống đang bận. Hãy thử lại sau',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
