// import { firebase } from 'firebase/app'

import firebase from 'firebase'
import "firebase/storage"

export const firebaseConfig = {
  apiKey: "AIzaSyAQ0hcOD6lBwuL3BpLRBYLPp5D1hZ1PBTw",
  authDomain: "ansinh-760d4.firebaseapp.com",
  projectId: "ansinh-760d4",
  storageBucket: "ansinh-760d4.appspot.com",
  messagingSenderId: "433658126412",
  appId: "1:433658126412:web:7487e1d38bbcc91b4f94e9",
  measurementId: "G-1PZQ52FQ0Y"
  };
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const storage = firebaseApp.storage();
let authentication = firebaseApp.auth();

let baiviet = db.collection("baiviet");
let perpose = db.collection("perpose");
let support = db.collection("support");
let informationSupport = db.collection("informationSupport");
let infoTeacher = db.collection("infoTeacher");

export { baiviet, perpose, support, informationSupport, authentication, storage,infoTeacher }