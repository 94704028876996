<template>
  <div class="container">
    <div class="container-footer">
      <div style="display: flex">
        <i class="fa-solid fa-file-lines" style="margin-top: 8px"></i>
        <p style="margin: 0; padding: 6px">
          Dự án An sinh cho Sinh viên Bách Khoa
        </p>
      </div>
      <div style="display: flex">
        <i class="fa-solid fa-phone" style="margin-top: 8px"></i>
        <p style="margin: 0; padding: 6px">Liên hệ: 0397613784</p>
      </div>
      <div style="display: flex">
        <i class="fa-solid fa-location-dot" style="margin-top: 8px"></i>
        <p style="margin: 0; padding: 6px">
          Địa chỉ: Tòa nhà A17, Tạ Quang Bửu, Bách Khoa, Hai Bà Trưng, Hà Nội,
          Vietnam
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
//   background: #0094947a;
  // height: 1400px;
//   max-width: 600px;
//   margin: 0 auto;
  position: relative;
  .container-footer {
    background: #009394;
    color: #fff;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.15);
    padding: 8px 20px;
    p {
      font-size: 14px;
    }
  }
}
</style>
