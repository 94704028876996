import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/vi'

import { LMap, LTileLayer, LMarker,LIcon } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-icon', LIcon);
Vue.config.productionTip = false
Vue.use(ElementUI, { locale })

// const http = require('https')
// const fs = require('fs')
// const httpPort = 80

// http
//   .createServer((req, res) => {
//     fs.readFile('index.html', 'utf-8', (err, content) => {
//       if (err) {
//         console.log('We cannot open "index.html" file.')
//       }

//       res.writeHead(200, {
//         'Content-Type': 'text/html; charset=utf-8',
//       })

//       res.end(content)
//     })
//   })
//   .listen(httpPort, () => {
//     console.log('Server listening on: http://localhost:%s', httpPort)
//   })

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
